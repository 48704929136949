import React, { useState } from "react";
import axios from "axios";
import Papa from "papaparse";
import * as XLSX from "xlsx";

const HotelForm = () => {
  const [hotelId, setHotelId] = useState("");
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Fetch the CSV data from the backend
      const response = await axios.post(
        "https://dev-backend.finkraftai.com/download",
        { hotel_id: hotelId }
      );

      const { filename, filedata } = response.data;

      // Decode the base64 data into a CSV string
      const csv = atob(filedata);

      // Parse CSV data
      const results = Papa.parse(csv, { header: true });
      const data = results.data;

      // Ensure there is data
      if (!data || data.length === 0) {
        setNotification("No data found in CSV.");
        return;
      }

      // Extract hotel name and GSTIN from the data
      const hotelName = data[0]["Hotel Name"] || "UnnamedHotel";
      const hotelGSTIN = data[0]["Hotel GSTIN"] || "NoGSTIN";

      // Split data into Booking and 2B
      const bookingData = data.filter((row) => row["Dataset"] === "Booking");
      const twoBData = data.filter((row) => row["Dataset"] === "2B");

      // Prepare data for XLSX
      const workbook = XLSX.utils.book_new();
      const bookingSheet = XLSX.utils.json_to_sheet(bookingData);
      const twoBSheet = XLSX.utils.json_to_sheet(twoBData);

      XLSX.utils.book_append_sheet(workbook, bookingSheet, "Booking");
      XLSX.utils.book_append_sheet(workbook, twoBSheet, "2B");

      // Create XLSX file and trigger download with the new naming format
      const excelFilename = `${hotelName}(${hotelGSTIN}).xlsx`;
      XLSX.writeFile(workbook, excelFilename);

      setNotification("Excel file has been downloaded successfully!");
    } catch (error) {
      console.error("Error processing CSV:", error);
      setNotification("Failed to process CSV data.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Hotel Information</h2>
      <form onSubmit={handleSubmit} style={{ display: "flex", gap: "4px" }}>
        <div style={{ display: "flex", gap: "5px" }}>
          <label htmlFor="hotelId">Hotel ID:</label>
          <input
            type="text"
            id="hotelId"
            value={hotelId}
            onChange={(e) => setHotelId(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? "Loading..." : "Submit"}
        </button>
      </form>
      {notification && <p>{notification}</p>}
    </div>
  );
};

export default HotelForm;
