import { Input, Spin, Tag } from "antd";
import "./HotelSearch.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { StopOutlined } from "@ant-design/icons";
import HotelIcon from "../../assets/hoteicon.png";
import axios from "axios";
import { param } from "jquery";
import { useNavigate } from "react-router-dom";

export default function HotelSearch(props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredHotel, setFilteredHotels] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hotelList, setHotelList] = useState([]);
  const searchRef = useRef(null); // Ref to detect clicks outside
  const navigate = useNavigate();
  // Debounced search function
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const fetchUsers = async (input) => {
    setLoading(true);
    setDropdownVisible(true);
    const esUrl =
      "https://88f84af300b542ef829bf72245776ad4.centralindia.azure.elastic-cloud.com:9243";
    const esIndex = "HotelNameSearch";
    const scroll_query = {
      size: 20,
      query: {
        bool: {
          must: [
            {
              match_phrase_prefix: {
                "Hotel Name": {
                  query: input.target.value?.toLowerCase(),
                  slop: 0,
                },
              },
            },
          ],
        },
      },
    };
    const response = await axios.post(
      `${esUrl}/${esIndex}/_search?scroll=1m`,
      scroll_query,
      {
        headers: {
          Authorization:
            "apiKey WUZyclpaSUJnR0FRZTlTZ2xYazQ6eWR3VnRMd1FTaXktN2hEb05MN2JVUQ==",
        },
      }
    );
    console.log("elstic search: ", response.data);
    setHotelList(response.data?.hits.hits);
    // Simulate fetching from an API
    setLoading(false);
  };

  const searchUser = useCallback(
    debounce((input) => {
      if (input === "") {
        setFilteredHotels([]);
        setDropdownVisible(false);
        setLoading(false);
      } else {
        fetchUsers(input);
      }
    }, 300),
    []
  );

  const handleInputChange = (event) => {
    const input = event.target.value?.toLowerCase() || "";
    setSearchTerm(input);
    searchUser(input); // Call debounced function
  };

  const selectUser = (user) => {
    setSearchTerm(user);
    setDropdownVisible(false);
  };

  // Hide dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="HotelSearchContainer" ref={searchRef}>
      <div
        className="SearchBox"
        style={{
          borderBottomLeftRadius: dropdownVisible ? 0 : 12,
          borderBottomRightRadius: dropdownVisible ? 0 : 12,
        }}
      >
        <Input placeholder="Search hotel" onChange={searchUser} />
      </div>
      <small>
        We care about your data in our <b>privacy policy</b>
      </small>

      {dropdownVisible && (
        <div
          className="dropdown"
          style={{ height: hotelList.length > 0 ? 600 : 64 }}
        >
          {loading ? (
            <div className="LoadingContainer">
              <Spin />
            </div>
          ) : hotelList.length > 0 ? (
            hotelList.map((hotel, index) => (
              <div
                key={index}
                className="dropdown-item"
                onClick={() => navigate("/bookings/" + hotel?._source?.id)}
              >
                <img src={HotelIcon} alt="HotelIcon" />

                <div className="HotelInfo">
                  <div>
                    {hotel?._source?.["Hotel Name"]}
                    {/* Keep existing styling for hotel name */}
                    <span
                      style={{
                        fontSize: "14px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {" - Pending Invoices: "}
                      {hotel?._source?.pending_invoice_count}
                    </span>
                  </div>
                  <small>{hotel?._source?.["Hotel Address"]}</small>
                </div>
              </div>
            ))
          ) : (
            <div className="no-results">
              <StopOutlined style={{ marginRight: 6 }} />
              No hotel found Looks like you haven't{" "}
              <b onClick={props.showRegister && props.showRegister}>
                registered yourself
              </b>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
